

.who-we-are-section {
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: #000; /* Fallback background color */
  height: 100vh;
  width: 100%;
  position: relative;
  overflow: hidden;

}

/* Background image styling */
.who-we-are-bg {
  /* background-image: url("../../assets/images/black screen.png"); */
  background-image: url("../../assets/images/webp/black screen.webp"); /* Path to the black screen image */
  background-size: cover;
  background-position: center;
  height: 100%;
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
}

/* Heading image styling */
.who-we-are-heading-img {
  max-width: 70%;
  height: auto;
  margin-top: 5%;
  z-index: 1;
}

/* Bottom image styling */
.who-we-are-bottom-img {
  max-width: 100%;
  /* height: auto; */
  z-index: 1;
  height: 60%;
}

/* Media Queries for Responsiveness */

/* For tablets */
@media (max-width: 1000px) {
  .who-we-are-heading-img {
    max-width: 50%;
  }
  .who-we-are-bottom-img {
    max-width: 90%;
  }
}
@media (max-width: 900px) {
  .who-we-are-bottom-img {
    max-width: 100%;
  }
}
@media (max-width: 768px) {
  .who-we-are-bottom-img {
    max-width: 120%; /* Adjust size for smaller screens */
  }
}

@media (min-width: 600px) and (max-width: 602px) {
  .who-we-are-heading-img,
  .who-we-are-bottom-img {
    max-width: 20%; /* Adjust size for smaller screens */
  }
}
/* For mobile phones */
@media (max-width: 480px) {
  
  .who-we-are-bottom-img {
    max-width: 135%; /* Take up full width for small screens */
  }
}

@media (max-width: 480px) {
  .who-we-are-heading-img
{
    max-width: 120%; /* Take up full width for small screens */
  }
}

@media (min-width: 103px) {
  .who-we-are-bottom-img-border {
    max-width: 100%;
    display: none;
  }
  .text-overlay {
    display: none;
  }

  .image-frame {
    display: none;
  }
}

/* Media query for mobile view */
@media only screen and (max-width: 1000px) {
  /* Example styles for mobile view */
  .who-we-are-section {
    height: 60vh;
  }
}

