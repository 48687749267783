.header {
  position: relative;
  top: 0;
  left: 0;
  right: 0;
  z-index: 1000;
  background: rgba(0, 0, 0, 0.9);
  padding: 10px 20px;
  color: white;
  font-family: Poppins, sans-serif;
  font-size: 20px;
  font-style: normal;
  font-weight: 700;
  line-height: 16px;

  
  overflow: visible;
}

.header::before {
  content: '';
  position: absolute;
  top: -10px; 
  left: -20px; 
  right: -20px;
  bottom: 0; 
  background: radial-gradient(circle, rgba(255, 255, 255, 0.35), rgba(255, 255, 255, 0) 90%);
  z-index: -1;
  pointer-events: none;
}




.header-content {
  display: flex;
  justify-content: space-between; 
  align-items: center;
  margin: 0 auto;
}

.logo-brand {
  display: flex;
  align-items: center; 
}

.logo img {
  max-width: 100px;
  height: auto;
  border-radius: 50%;
}

.brand-text .brand-name {
  font-size: 24px;
  font-weight: bold;
  margin-left: 10px;
  font-family: "Luckiest Guy", sans-serif; 
}


.brand-name .ai-text {
  color: #ffd700; /* Color for "AI" */
}

.brand-name .jokercat-text {
  color: white; /* Color for "JOKERCAT" */
}
.navigation {
  display: flex;
  justify-content: center; 
  flex-grow: 1; 
  gap: 100px;
  align-items: center;
}

.navigation.open {
  flex-direction: column;
  position: absolute;
  top: 100px;
  left: 0;
  right: 0;
   background: rgba(0, 0, 0, 0.5); 
  backdrop-filter: blur(10px); 
  -webkit-backdrop-filter: blur(10px); 
  border-radius:20px;
  padding: 10px 20px;
  z-index: 1000;
}

.nav-link {
  color: white;
  text-decoration: none;
  font-size: 18px;
  font-weight: bold;
  transition: color 0.3s, text-shadow 0.3s;
  position: relative;
}

.nav-link::after {
  content: "";
  position: absolute;
  bottom: 0;
  left: 0;
  top: 22px;
  width: 100%;
  height: 3px;
  background-color: white;
  transition: background-color 0.3s;
}

.nav-link:hover {
  color: #00ff00;
  text-shadow: 0 0 10px #00ff00, 0 0 20px #00ff00, 0 0 30px #00ff00;
}

.nav-link:hover::after {
  background-color: #00ff00;
}

.nav-link:focus,
.nav-link:active {
  color: white;
  text-shadow: none;
}

.highlight {
  color: #00ff00;
  text-shadow: 0 0 10px #00ff00, 0 0 20px #00ff00, 0 0 30px #00ff00;
}

.buy-now-button {
  background: #FFD700;
  color: #000000;
  padding: 12px 30px;
  border: none;
  border-radius: 25px;
  font-family: 'Poppins', sans-serif;
  font-weight: 700;
  font-size: 18px;
  cursor: pointer;
  box-shadow: 0 0 15px rgba(255, 215, 0, 0.5);
  transition: transform 0.2s ease;
  margin-left: auto; 
}

.highlight_yellow {
  color: white;
  text-shadow: 0 0 10px white, 0 0 20px white, 0 0 30px white;
}

.menu-toggle {
  display: none;
  font-size: 24px;
  background: none;
  border: none;
  cursor: pointer;
  color: white;
}
.divider {
 display: none;
}

@media (max-width: 768px) {
  .menu-toggle {
    display: block;
  }
  .divider {
    width: 100%;
    height: 1.5px;
    background-color: #00ff00; /* Green color */
    margin: 20px 0; /* Space between link and divider */
  }
  .divider {
    display: block; /* Ensure it's visible in mobile view */
  }
  .navigation {
    display: none;
    flex-direction: column;
    gap: 10px;
    position: absolute;
    top: 60px;
    left: 0;
    right: 0;
    background: rgba(0, 0, 0, 0.9);
    padding: 10px 20px;
    z-index: 1000;
  }

  .navigation.open {
    display: flex;
  }

  .header-content {
    flex-wrap: wrap;
    justify-content: space-between;
  }

  .logo img {
    width: 90px;
  }

  .buy-now-button {
    font-size: 18px;
  }
  
.nav-link::after {
  display: none;
}


}

@media only screen and (min-width: 768px) and (max-width: 1088px) {
  .brand-text .brand-name{
    display: none;
  }
  .navigation {
    gap: 50px;
}
} 
@media (max-width: 480px) {
  .brand-text .brand-name {
    font-size: 15px;
  }
  .logo img {
        width: 50px;
    }
  .nav-link {
    font-size: 16px;
  }

  .buy-now-button {
    font-size: 10px;
        padding: 4px 8px;
  }
} 

@media only screen and (min-width: 294px) and (max-width: 480px) {
  .navigation.open {  
    top: 71px;
}
}
