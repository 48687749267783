.App {
  text-align: center;
}

.global-cloud-overlay {
  position: absolute;
  left: 50%;
  margin-top: -509px; /* Keep the cloud positioned perfectly */
  transform: translateX(-50%) translateY(0); /* Initialize transform */
  z-index: 5; /* Ensure it's above other content */
  pointer-events: none; /* Prevent interaction issues */
  /* animation: float 3s ease-in-out infinite; */
}
