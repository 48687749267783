/* Roadmap: Section Styles */
.roadmap-how-to-buy-section {
  position: relative;
  width: 100%;
  height: 100vh;
  /* background-image: url("../../assets/images/bgnewwww (1).png"); */
  background-image: url("../../assets/images/webp/bgnewwww (1).webp"); /* Updated to CSS */
  background-size: cover;
  background-position: center;
  display: flex;
  justify-content: center;
  align-items: center;
  overflow: hidden;
  min-height: 970px;
}

/* Roadmap: Heading Image */
.roadmap-heading-image {
  position: absolute;
  top: -100px;
  width: 50%;
  transform: scale(0.8);
  opacity: 0;
  transition: all 0.8s ease-out;
}

.roadmap-heading-image.visible {
  transform: scale(1);
  opacity: 1;
}

/* Roadmap: Center Cat */
.roadmap-center-cat {
  position: relative;
  z-index: 2;
}

.roadmap-cat-image {
  position: relative;
  animation: roadmap-float 3s ease-in-out infinite;
  width:800px;
  height:800px;
  margin-top:250px;
}

/* Roadmap: Steps Container */


.roadmap-step.visible {
  opacity: 1;
  transform: scale(1);
}

.roadmap-step-text {
  display: none;
}

.roadmap-step-icon{
  width: 600px;
  height: 600px;
}

/* Roadmap: Step 1 & Step 2 (Left Side) */

.roadmap-step1 {
  top: 20%;
  left: 10%;
  transform: translate(-50%, -50%);
}

.roadmap-step2 {
  top: 50%;
  left: 15%;
  transform: translate(-50%, -50%);
}

/* Roadmap: Step 3 & Step 4 (Right Side) */


.roadmap-step3 {
  top: 20%;
  right: 10%;
  transform: translate(50%, -50%);
}

.roadmap-step4 {
  top: 50%;
  right: 5%;
  transform: translate(50%, -50%);
}

/* Roadmap: Floating Animation for the Cat */
@keyframes roadmap-float {
  0%, 100% {
    transform: translateY(0);
  }
  50% {
    transform: translateY(-10px);
  }
}
/* Media query for mobile view */
@media only screen and (min-width: 650px) and (max-width: 1000px) {
/* Example styles for mobile view */
.roadmap-how-to-buy-section{
  
  height: 100vh;
  min-height: 970px;
  
}
.roadmap-left {
  padding: 15px;
  margin-right: 20px;
}
.roadmap-right {
  padding: 15px;
  /* margin-left: 20px; */
}
.roadmap-steps-container {
  position: absolute;
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
}

/* Roadmap: Step Styles */
.roadmap-step {
  position: absolute;
  text-align: center;
  opacity: 0;
  transform: scale(0.5);
  transition: all 0.8s ease-in-out;
}
.roadmap-heading-image {
  /* top:50px; */
  margin-top: 40px;
}
.roadmap-cat-image{
  width: 300px;
  height: 300px;
  margin-top:0px
}
.roadmap-step-icon{
  width: 300px;
  height: 300px;
}
.roadmap-step1 {
  top: 13%;
  left: 5%;
  transform: translate(-50%, -50%);
}

.roadmap-step2 {
  top: 60%;
  left: 10%;
  transform: translate(-50%, -50%);
}

.roadmap-step3 {
  top: 13%;
  right: 5%;
  transform: translate(50%, -50%);
}

.roadmap-step4 {
  top: 60%;
  right: 0%;
  transform: translate(50%, -50%);
}

.cardStyle {
  width: 381px;
  height: 333px;
  border-radius: 16px;
  position: relative;
  overflow: hidden;
  /* background: linear-gradient(145deg, #7b1fa2, #ab47bc); */
  background: linear-gradient(145deg, #AA0B61, #ab47bc);
  color: #fff;
  box-shadow: 0 0 20px 10px rgba(170, 11, 97, 0.3);
  border: 1px solid #01B7C5;
}
.headerStyle {
  border-top-left-radius: 16px;
  border-top-right-radius: 16px;
  padding: 34px;
  text-align: center;
  font-weight: bold;
  color: white;
  font-family: Comic Sans MS;
  padding-left: 10px;
}

.footerStyle {
  position: absolute;
  bottom: 16px;
  right: 16px;
}


.cardContentStyle {
  background: #6A0DAD;
  height: 63%;
  padding: 16px;
  overflow-y: auto;
  border-top-left-radius: 18px;
  color: white;
  font-family: poppins;
}
} 

@media only screen and (min-width: 1395px) and (max-width: 2400px) {
/* Example styles for mobile view */
.roadmap-how-to-buy-section{
  
  height: 100vh;
  min-height: 970px;
  
}
.roadmap-left {
  padding: 15px;
  margin-right: 20px;
}
.roadmap-right {
  padding: 15px;
  /* margin-left: 20px; */
}
.roadmap-steps-container {
  position: absolute;
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
}

/* Roadmap: Step Styles */
.roadmap-step {
  position: absolute;
  text-align: center;
  opacity: 0;
  transform: scale(0.5);
  transition: all 0.8s ease-in-out;
}
.roadmap-heading-image {
  /* top:50px; */
}
.roadmap-cat-image{
  width: 600px;
  height: 600px;
  margin-top:0px
}
.roadmap-step-icon{
  width: 400px;
  height: 400px;
}
.roadmap-step1 {
  top: 13%;
  left: 8%;
  transform: translate(-50%, -50%);
}

.roadmap-step2 {
  top: 60%;
  left: 3%;
  transform: translate(-50%, -50%);
}

.roadmap-step3 {
  top: 13%;
  right: 8%;
  transform: translate(50%, -50%);
}

.roadmap-step4 {
  top: 60%;
  right: 3%;
  transform: translate(50%, -50%);
}
.cardStyle {
  width: 387px;
  height: 333px;
  border-radius: 16px;
  position: relative;
  overflow: hidden;
  /* background: linear-gradient(145deg, #7b1fa2, #ab47bc); */
  background: linear-gradient(145deg, #AA0B61, #ab47bc);
  color: #fff;
  box-shadow: 0 0 20px 10px rgba(170, 11, 97, 0.3);
  border: 1px solid #01B7C5;
}
.headerStyle {
  border-top-left-radius: 16px;
  border-top-right-radius: 16px;
  padding: 34px;
  text-align: center;
  font-weight: bold;
  color: white;
  font-family: Comic Sans MS;
  padding-left: 10px;
}

.footerStyle {
  position: absolute;
  bottom: 16px;
  right: 16px;
}


.cardContentStyle {
  background: #6A0DAD;
  height: 63%;
  padding: 16px;
  overflow-y: auto;
  border-top-left-radius: 18px;
  color: white;
  font-family: poppins;
}
}

@media only screen and (min-width: 1001px) and (max-width: 1394px) {
  /* Example styles for mobile view */
  .roadmap-how-to-buy-section{
    
    height: 100vh;
    min-height: 970px;
    
  }
  .roadmap-left {
    padding: 15px;
    margin-right: 20px;
  }
  .roadmap-right {
    padding: 15px;
    /* margin-left: 20px; */
  }
  .roadmap-steps-container {
    position: absolute;
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
  }
  
  /* Roadmap: Step Styles */
  .roadmap-step {
    position: absolute;
    text-align: center;
    opacity: 0;
    transform: scale(0.5);
    transition: all 0.8s ease-in-out;
  }
  .roadmap-heading-image {
    /* top:50px; */
    margin-top: 34px;
  }
  .roadmap-cat-image{
    width: 450px;
    height: 450px;
    margin-top:0px
  }
  .roadmap-step-icon{
    width: 400px;
    height: 400px;
  }
  .roadmap-step1 {
    top: 13%;
    left: 8%;
    transform: translate(-50%, -50%);
  }
  
  .roadmap-step2 {
    top: 60%;
    left: 3%;
    transform: translate(-50%, -50%);
  }
  
  .roadmap-step3 {
    top: 13%;
    right: 8%;
    transform: translate(50%, -50%);
  }
  
  .roadmap-step4 {
    top: 60%;
    right: 3%;
    transform: translate(50%, -50%);
  }
  .cardStyle {
    width: 388px;
    height: 335px;
    border-radius: 16px;
    position: relative;
    overflow: hidden;
    /* background: linear-gradient(145deg, #7b1fa2, #ab47bc); */
    background: linear-gradient(145deg, #AA0B61, #ab47bc);
    color: #fff;
    box-shadow: 0 0 20px 10px rgba(170, 11, 97, 0.3);
    border: 1px solid #01B7C5;
  }

  .headerStyle {
    border-top-left-radius: 16px;
    border-top-right-radius: 16px;
    padding: 34px;
    text-align: center;
    font-weight: bold;
    color: white;
    font-family: Comic Sans MS;
    padding-left: 10px;
  }
  
  .footerStyle {
    position: absolute;
    bottom: 21px;
    width: 100%;
    /* margin-left: 57px; */
    display: flex;
    justify-content: center;
  }

  .rocketIcon {
    position: relative;
    animation: rocketMove 1s ease-in-out forwards;
  }

  @keyframes rocketMove {
    0% {
      transform: translateX(0%);
    }
    100% {
      transform: translateX(150%);
    }
  }
  .cardContentStyle {
    background: #6A0DAD;
    height: 63%;
    padding: 16px;
    overflow-y: auto;
    border-top-left-radius: 18px;
    color: white;
    font-family: poppins;
  }
  }


@media only screen and (min-width: 421px) and (max-width: 649px) {
/* Example styles for mobile view */
.roadmap-how-to-buy-section{
  
  /* height: 50vh; */
  height: 100%;
  min-height: 970px;
  
}
.roadmap-steps-container {
  /* position: absolute; */
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin-top: 80px;
}

/* Roadmap: Step Styles */
.roadmap-step {
  /* position: absolute; */
  text-align: center;
  opacity: 0;
  transform: scale(0.5);
  transition: all 0.8s ease-in-out;
  width: 100%;
}

.roadmap-heading-image {
  /* top:50px; */
  margin-top: 96px;
}
.roadmap-cat-image{
  display: none;
}
.roadmap-step-icon{
  width: 150px;
  height: 150px;
}

.cardContainer {
  display: flex;
  flex-wrap: wrap;
  gap: 16px; /* Adds space between cards */
}

.cardStyle {
  width: 381px;
  height: 332px;
  border-radius: 16px;
  position: relative;
  overflow: hidden;
  background: linear-gradient(145deg, #AA0B61, #ab47bc);

  color: #fff;
  box-shadow: 0 0 20px 10px rgba(170, 11, 97, 0.3);
  border: 1px solid #01B7C5;
}
.roadmap-left {
  float: right;
  margin-right: 7%;
}
.roadmap-right {
  padding: 15px;
  /* margin-left: 20px; */
  margin-left: 7%; 
}

.cardLeft {
margin-left: 7%;
}

.cardRight {
  margin-right: 7%; 
  float: right;
}
.headerStyle {
  border-top-left-radius: 16px;
  border-top-right-radius: 16px;
  padding: 34px;
  text-align: center;
  font-weight: bold;
  color: white;
  font-family: Comic Sans MS;
  padding-left: 10px;
}

.footerStyle {
  position: absolute;
  bottom: 16px;
  right: 16px;
}

.cardContentStyle {
  background: #6A0DAD;
  height: 63%;
  padding: 16px;
  overflow-y: auto;
  border-top-left-radius: 18px;
  color: white;
  font-family: poppins;
}

} 

@media only screen and (min-width: 250px) and (max-width: 420px) {
  /* Example styles for mobile view */
  .roadmap-how-to-buy-section{
    
    /* height: 50vh; */
    height: 100%;
    min-height: 970px;
    
  }
  .roadmap-steps-container {
    /* position: absolute; */
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    margin-top: 80px;
  }
  
  /* Roadmap: Step Styles */
  .roadmap-step {
    /* position: absolute; */
    text-align: center;
    opacity: 0;
    transform: scale(0.5);
    transition: all 0.8s ease-in-out;
    width: 100%;
    display: flex;
    justify-content: center;
  }
  
  .roadmap-heading-image {
    /* top:50px; */
    margin-top: 96px;
  }
  .roadmap-cat-image{
    display: none;
  }
  .roadmap-step-icon{
    width: 150px;
    height: 150px;
  }
  
  .cardContainer {
    display: flex;
    flex-wrap: wrap;
    gap: 16px; /* Adds space between cards */
  }
  
  .cardStyle {
    width: 381px;
    height: 332px;
    border-radius: 16px;
    position: relative;
    overflow: hidden;
    background: linear-gradient(145deg, #AA0B61, #ab47bc);
  
    color: #fff;
    box-shadow: 0 0 20px 10px rgba(170, 11, 97, 0.3);
    border: 1px solid #01B7C5;
    margin-top: 8px;
    margin-bottom: 8px;
  }
  /* .roadmap-left {
    float: right;
    margin-right: 7%;
  } */
  /* .roadmap-right {
    padding: 15px;
  
    margin-left: 7%; 
  } */
  
  /* .cardLeft {
  margin-left: 7%;
  }
  
  .cardRight {
    margin-right: 7%; 
    float: right;
  } */
  .headerStyle {
    border-top-left-radius: 16px;
    border-top-right-radius: 16px;
    padding: 34px;
    text-align: center;
    font-weight: bold;
    color: white;
    font-family: Comic Sans MS;
    padding-left: 10px;
  }
  
  .footerStyle {
    position: absolute;
    bottom: 16px;
    right: 16px;
  }
  
  .cardContentStyle {
    background: #6A0DAD;
    height: 63%;
    padding: 16px;
    overflow-y: auto;
    border-top-left-radius: 18px;
    color: white;
    font-family: poppins;
  }
  
  } 




