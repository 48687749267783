/* Section Styling */

/* @import url('https://fonts.googleapis.com/css2?family=Luckiest+Guy&display=swap'); */
.banner-section {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 400px; /* Adjust the height of the section */
    background-size: cover;
    background-position: center;
    padding: 20px;
    color: white;
    text-align: center;
    background-color: #0b1120;
  }
  
  /* Card Container */
  .banner-card {
    background-color: rgba(255, 255, 255, 0.2); /* Light white with more transparency */
    backdrop-filter: blur(5px); /* Subtle blur effect */
    border-radius: 20px;
    padding: 30px;
    /* max-width: 900px;  */
    width: 90%; 
    margin: 0 auto;
  }
  
  /* Banner Text */
  .banner-text {
    font-family: 'Luckiest Guy', sans-serif; /* Use Luckiest Guy font */
    font-size: 24px;
    line-height: 1.6;
    font-weight: normal;
    text-align: center;
    text-shadow: 2px 2px 10px rgba(0, 0, 0, 0.7); /* Slight text shadow for readability */
  }
  
  /* Highlighted Text */
  .highlight-text {
    color: #00FF00; /* Green color for $AIJokerCat */
  }
  
  /* Optional: Add a cool hover effect or animation */
  .banner-card:hover {
    transform: scale(1.05); /* Slight zoom effect on hover */
    transition: transform 0.3s ease-in-out;
  }
  
  
@media only screen and (max-width: 410px) {
    .live-viewers-section {
      gap: 20px;
      justify-content: center;
  }
  } 
@media only screen and (min-width: 984px) and (max-width: 1394px) {
    .banner-text {
        font-size: 20px;
        line-height: 1.6;
    }
  } 

  @media only screen and (min-width: 705px) and (max-width: 983px) {
    .banner-section {
        height: 500px;
    }
  } 
  @media only screen and (min-width: 445px) and (max-width: 704px) {
    .banner-section {
        height: 700px;
    }
  } 
  @media only screen and (min-width: 300px) and (max-width: 444px) {
    .banner-text {
        
        font-size: 15px;
    }
    .banner-section {
        height: 500px;
    }
  } 