/* Base Styles */
.herosection1-hero-section {
  position: relative;
  width: 100%;
  height: 100vh;
  overflow: hidden;
}

.herosection1-background-video {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  object-fit: cover;
  z-index: 0;
}

.herosection1-hero-content {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  z-index: 2;
  text-align: center;
  color: white;
}

.herosection1-buttons {
  display: flex;
  flex-direction: column;
  gap: 100px; /* Space between buttons */
}

.herosection1-buttons-left {
  align-items: flex-end; /* Align buttons to the right on the left side */
}

.herosection1-buttons-right {
  align-items: flex-start; /* Align buttons to the left on the right side */
}

.herosection1-button-icon {
  width: 200px;
  height: 200px;
  cursor: pointer;
  transition: transform 0.2s ease-in-out;
}

.herosection1-button-icon:hover {
  transform: scale(1.2);
}

.herosection1-hero-image {
  width: 500px;
  height: 500px;
}
@media only screen and (min-width: 560px) and (max-width: 855px) {
  
  .herosection1-hero-section {
    height: 50vh;
  }
  .herosection1-hero-image{
    width:300px;
    height:300px;
  }
  .herosection1-button-icon {
    width: 150px;
    height: 150px;
  }
} 
@media only screen and (min-width: 768px) and (max-width: 1181px) {
  
  .herosection1-hero-section {
    height: 50vh;
  }
} 
@media only screen and (min-width: 439px) and (max-width: 559px) {
  
  .herosection1-hero-image{
    width:250px;
    height:250px;
  }
  .herosection1-button-icon {
    width: 100px;
    height: 100px;
  }
} 
@media only screen and (min-width: 250px) and (max-width: 438px) {
 
  .herosection1-hero-image{
    width:150px;
    height:150px;
  }
  .herosection1-button-icon {
    width: 100px;
    height: 100px;
  }
}

@media only screen and (max-width: 438px) {
  .herosection1-hero-section {
    height: 50vh;
  }
} 

@media only screen and (min-width: 439px) and (max-width: 559px) {

  .herosection1-hero-section {
    height: 50vh;
  } 
} 

@media only screen and (max-width: 600px) {
  .herosection1-center-container {
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 200px;
  }
}

@media only screen and (min-width: 601px) {
  .herosection1-center-container {
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 420px;
  }
}
