.howtobuy-section {
  position: relative;
  width: 100%;
  /* min-height: 100%; */
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: #f5f5f5;
  overflow: hidden;
}

.howtobuy-bg-image {
  width: 100%;
  height: 100%;
  background-image: url("../../assets/images/how to buy (1).png");
  background-size: cover;
  background-position: center;
  display: flex;
  justify-content: center;
  align-items: center;
}

.howtobuy-inner-image {
  cursor: pointer;
  perspective: 1000px;
  transition: transform 1s, opacity 0.5s ease-in-out;
  width: 100%;
  max-width: 1200px;
  display: flex;
  justify-content: center;
  padding: 50px;
}

/* .howtobuy-image {
  max-width: 100%;
  height: auto;
  border-radius: 8px;
  opacity: 0;
  transform: rotateY(90deg);
  transition: transform 1s, opacity 0.8s ease-in-out;
} */

.howtobuy-visible {
  opacity: 1;
  transform: rotateY(0deg);
}

/* Responsive breakpoints */
@media screen and (max-width: 1200px) {
  .howtobuy-inner-image {
    max-width: 100%;
  }
}

@media screen and (max-width: 768px) {
  .howtobuy-bg-image {
    padding: 1rem;
  }

  .howtobuy-inner-image {
    max-width: 100%;
  }
}

/* Prevent conflicts with other components */
.howtobuy-section *,
.howtobuy-section *::before,
.howtobuy-section *::after {
  box-sizing: border-box;
}

.howtobuy-image-header {
  text-align: center;
  font-size: 2.5rem;
  font-family: "Luckiest Guy";
  color: white;
  margin-bottom: 85px;
  display: inline-flex;
  align-items: center;
  gap: 10px;
  /* box-shadow: 2px 2px 5px white; */
  /* background-color: transparent; */

  color: white;
  background: transparent; /* Ensures the background is transparent */
  text-shadow: 1px 1px 2px black, 0 0 25px white, 0 0 5px white; /* Increases the spread of the shadow */
}

.howtobuy-image-header-mobile {
  text-align: center;
  font-size: 2.5rem;
  font-family: "Luckiest Guy";
  color: white;
  padding-bottom: 40px;
  padding-top: 20px;
  /* display: inline-flex; */
  align-items: center;
  gap: 10px;
  width: 100%;
  /* box-shadow: 2px 2px 5px white; */
  /* background-color: transparent; */

  color: white;
  background: transparent; /* Ensures the background is transparent */
  text-shadow: 1px 1px 2px black, 0 0 25px white, 0 0 5px white; /* Increases the spread of the shadow */
}

.highlighted-text {
  background: transparent;
  color: #00ff00;
  text-shadow: 0px 0px 1px black, 0 0 25px #00ff00, 0 0 50px #00ff00;
}

.card {
  display: flex;
  align-items: flex-start;
  justify-content: space-between;
  flex-wrap: wrap;
  gap: 20px;
}

@media (max-width: 800px) {
  .desktop {
    display: none;
  }
  .stepMobile {
    /* width: 540px;
  height: 95px; */
    background: white;
    border-radius: 81px;
    padding: 11px;
    display: flex;
    align-items: center;
    gap: 10px;
    box-shadow: 0 2px 5px rgba(0, 0, 0, 0.4);
    margin-bottom: 20px;
  }
  .stepMobile:nth-child(odd) {
    /* margin-left: 12%; */
  }
  .stepMobile img {
    width: 70px;
    height: 70px;
    border-radius: 50%;
    /* background: #444; */
  }
  .stepMobile.reverse {
    /* margin-left: 40px; */
    flex-direction: row-reverse;
  }

  .stepsMobile {
    display: flex;
    /* flex-wrap: wrap; */
    justify-content: space-between;
    width: 100%;
    /* height: 650PX; */
    margin-top: 20px;
    flex-direction: column;
  }

  .glassContainer {
    background-color: rgba(255, 255, 255, 0.2);
    border-radius: 10px;
    width: 90%;
    max-width: 500px;
    padding: 2rem;
    /* padding-bottom: 120px; Added space for the overlapping cat */
    box-shadow: 0 10px 30px rgba(0, 0, 0, 0.1);
    border: 1px solid rgba(255, 255, 255, 0.3);
    position: relative;
    z-index: 10;
    margin: auto;
  }

  .cat-image {
    position: absolute;
    bottom: 40%;
    right: -88px;
    transform: translateY(50%);
    width: 641px;
    height: 685px;
    display: block;
  }
}

@media (min-width: 800px) {
  .mobile {
    display: none;
  }
  .step {
    width: 65%;
    height: 100px;
    background: white;
    border-radius: 81px;
    padding: 20px;
    display: flex;
    align-items: center;
    gap: 20px;
    box-shadow: 0 2px 5px rgba(0, 0, 0, 0.4);
    margin-bottom: 40px;
  }
  .step:nth-child(odd) {
    margin-left: 10%;
  }
  .step img {
    width: 80px;
    height: 80px;
    border-radius: 50%;
    /* background: #444; */
  }
  .step.reverse {
    margin-left: 30px;
    flex-direction: row-reverse;
  }

  .howtobuy-image {
    width: 100%;
    max-width: 1900px;
    height: 845px;

    border-radius: 81px;
    padding: 20px;
    /* box-shadow: 0 4px 10px rgba(0, 0, 0, 0.5); */
    position: relative;
    /* opacity: 0; */
    /* transform: rotateY(90deg);
    transition: transform 1s, opacity 0.8s ease-in-out; */
    background-color: rgba(255, 255, 255, 0.2);
    /* border-radius: 10px; */
    /* width: 90%; */
    /* max-width: 500px; */
    padding: 2rem;
    /* padding-bottom: 120px; Added space for the overlapping cat */
    box-shadow: 0 10px 30px rgba(0, 0, 0, 0.1);
    border: 1px solid rgba(255, 255, 255, 0.3);
    /* position: relative; */
    z-index: 10;
    margin: auto;
  }

  .cat-image {
    position: absolute;
    bottom: 40%;
    right: -15px;
    transform: translateY(50%);
    width: 36%;
    height: 70%;
    display: block;
  }

  .steps {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    width: 100%;
    height: 650px;
  }
  .how-to-buy-content-text-mobile {
    text-align: left;
    font-family: Satoshi, sans-serif;
    font-size: 16px;
    word-break: break-word;
  }
  .how-to-buy-content-text-mobile {
    text-align: left;
    font-family: Satoshi, sans-serif;
    font-size: 16px;
    word-break: break-word;
  }
}
.how-to-buy-content-text-mobile {
  text-align: left;
  font-family: Satoshi, sans-serif;
  font-size: 14px;
  word-break: break-word;
  padding: 10px;
}
@media (max-width: 580px) {
  .glassContainer {
    background-color: rgba(255, 255, 255, 0.2);
    border-radius: 10px;
    width: 75%;
    max-width: 500px;
    padding: 16px;
    box-shadow: 0 10px 30px rgba(0, 0, 0, 0.1);
    border: 1px solid rgba(255, 255, 255, 0.3);
    position: relative;
    z-index: 10;
    margin: auto;
  }
}
.copy-icon-mobile {
  width: 14px !important;
  vertical-align: middle;
  margin-left: 4px;
}
.venus-shadow {
  box-shadow: 0px 0px 15px #f5b35c;
}
.jupiter-shadow {
  box-shadow: 0px 0px 15px #f66212;
}
.earth-shadow {
  box-shadow: 0px 0px 15px #45c7ec;
}
.sun-shadow {
  box-shadow: 0px 0px 15px #f8b208;
}
.mars-shadow {
  box-shadow: 0px 0px 15px #b50703;
}