/* 
@import url('https://fonts.googleapis.com/css2?family=Luckiest+Guy&display=swap'); */

.live-viewers-section {
  display: flex;
  align-items: center;
  background-color: black;
  color: white;
  padding: 20px;
  /* gap: 20px; */
  justify-content: space-evenly;
}

.live-viewers-image img {
  /* height: 100px;
  width: auto; */
  border-radius: 10px;
}

.live-viewers-content {
  display: flex;
  flex-direction: row;
  justify-content: center;
  gap: 200px;
}

.heading-with-counter {
  text-align: center;
}

.heading-with-counter .heading {
  font-size: 24px;
  font-weight: bold;
  font-family: "Luckiest Guy", sans-serif; /* Ensure the font is loaded */
  color: white;
  text-shadow: 2px 2px 8px rgba(0, 0, 0, 0.7);
  margin-bottom: 5px;
}

.counter-container {
  font-size: 24px;
  font-weight: bold;
  font-family: "Luckiest Guy", sans-serif;
  color: white;
  text-shadow: 2px 2px 8px rgba(0, 0, 0, 0.7);
  margin-bottom: 5px;
}

.fallback-counter{
  font-size: 24px;
  font-weight: bold;
  font-family: "Luckiest Guy", sans-serif;
  color: #f8d101;
  text-shadow: 2px 2px 8px rgba(0, 0, 0, 0.7);
  margin-bottom: 5px;
} 


@media only screen and (max-width: 850px) {
  .live-viewers-content {
    flex-direction: column;
    gap: 10px;
}

.live-viewers-image img {
  height: 200px;
  width: 200px;
  border-radius: 10px;
}
} 


@media only screen and (max-width: 410px) {
  .heading-with-counter .heading {
    font-size: 14px;
    /* margin-bottom: 5px; */
}
.fallback-counter {
  font-size: 15px;
}
} 

@media only screen and (max-width: 410px) {
  .live-viewers-section {
    gap: 20px;
    justify-content: center;
}
} 

@media only screen and (max-width: 359px) {
  .live-viewers-section {
    gap: 1px;
    justify-content: center;
}
.live-viewers-image img {
  height: 150px;
  width: 150px;
  border-radius: 10px;
}
} 