/* .moving-strip {
 
  background-color: black;
  overflow: hidden;
  white-space: nowrap;
  position: relative;
  height: 110px; 
  display: flex;
  align-items: center;
}

.strip-content {
  display: inline-flex;
  align-items: center;
  animation: moveText 15s linear infinite;
  will-change: transform; 
}

.strip-text {
  color: white; 
  font-size: 50px; 
  font-weight: bold;
  margin: 0 20px;
  font-family: 'Luckiest Guy', sans-serif;
}

.strip-image {
  width: 150px; 
  height: auto;
  margin: 0 10px;
}

@keyframes moveText {
  0% {
    transform: translate3d(0px, 0px, 0px) scale3d(1, 1, 1) rotateX(0deg) rotateY(0deg) rotateZ(0deg);
  }
  100% {
    transform: translate3d(-50%, 0px, 0px) scale3d(1, 1, 1) rotateX(0deg) rotateY(0deg) rotateZ(0deg);
  }
}


.strip-content {
  width: 200%; 
}

.strip-content > * {
  flex-shrink: 0; 
}

.strip-content::after {
  content: '';
  display: inline-block;
  width: 100%;
}

.ai-text {
  color: #ffd700;
} */


.moving-strip {
  background-color: #3c414d;
  overflow: hidden;
  position: relative;
  height: 110px; 
  display: flex;
  align-items: center;
}

.strip-content {
  display: flex;
  align-items: center;
  animation: moveText 20s linear infinite;
}

.strip-text {
  color: white; 
  font-size: 50px; 
  font-weight: bold;
  margin: 0 20px;
  font-family: 'Luckiest Guy', sans-serif;
}

.strip-image {
  width: 150px; 
  height: auto;
  margin: 0 10px;
}

@keyframes moveText {
  0% {
    transform: translateX(0);
  }
  100% {
    transform: translateX(-50%);
  }
}

.strip-content > * {
  flex-shrink: 0; 
}

.strip-content::after {
  content: '';
  display: inline-block;
  width: 0;
}

.ai-text {
  color: #ffd700;
}
