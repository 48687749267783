.aijokercat-footer {
  background-color: #0B1120;
  width: 100%;
  padding: 40px 0;
  display: flex;
  flex-direction: column;
  align-items: center;
  color: white;
}

.aijokercat-footer-content {
  width: 90%;
  max-width: 1550px;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.aijokercat-logo-container {
  width: 100%;
  display: flex;
  justify-content: flex-start;
  margin-bottom: 0px;
}

.aijokercat-logo {
  width: 170px;
  height: 170px;
  border-radius: 50%;
}

.aijokercat-quick-links-section {
  width: 60%;
  text-align: left;
  margin-bottom: 20px;
  margin-left: 255px;
}

.aijokercat-quick-links-title {
  font-family: 'Satoshi', sans-serif;
  font-size: 24px;
  font-weight: 700;
  margin-bottom: 80px;
}

.aijokercat-links-grid {
  display: flex;
  justify-content: left;
  gap: 400px;
}

.aijokercat-links-column {
  display: flex;
  flex-direction: column;
  gap: 80px;
}

.aijokercat-link {
  font-family: 'Satoshi', sans-serif;
  font-size: 18px;
  font-weight: 400;
  color: white;
  position: relative;
  text-decoration: none;
  transition: color 0.3s;
  opacity: 65%;
}

.aijokercat-link::after {
  content: '';
  display: block;
  width: 50%;
  height: 2px;
  background-color: #FFD700; 
  margin-top: 5px;
  margin-left: 28%;
  transition: background-color 0.3s;
}


.aijokercat-link[data-accent="red"]::after {
  background-color: #FFD700;
  width: 90%;
}

.aijokercat-link[data-accent="white"]::after {
  background-color: #FFD700;
  width: 80%;
}

.aijokercat-link:hover::after {
  background-color: #1565c0;
}

.aijokercat-contact-section {
  width: 100%;
  text-align: right;
  margin: 20px 0;
}

.aijokercat-support-email {
  font-family: 'Satoshi', sans-serif;
  font-size: 18px;
  font-weight: 400;
  color: white;
  text-decoration: none;
}

.aijokercat-support-email .aijokercat-highlight {
  color: #FFD700;
}

.aijokercat-divider {
  width: 100%;
  height: 1.5px;
  background-color: rgba(255, 255, 255, 0.1);
  margin: 20px 0;
}

.aijokercat-footer-bottom {
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.aijokercat-social-icons {
  flex: 1;
  text-align: left;
}

.aijokercat-info-container {
  flex: 2;
  text-align: center;
}

.aijokercat-copyright-container {
  flex: 1;
  text-align: right;
}

.aijokercat-company-info,
.aijokercat-copyright {
  font-family: 'Satoshi', sans-serif;
  color: #FFFFFF;
}

.aijokercat-company-info {
  font-size: 20px;
  font-weight: 400;
}

.aijokercat-copyright {
  font-size: 16px;
  font-weight: 400;
}

.aijokercat-highlight {
  color: #FFD700;
}

/* Social Icons */
.aijokercat-social-icons {
  display: flex;
  gap: 20px; /* Adjust spacing between icons */
}

.aijokercat-social-icon {
  width: 40px;
  height: 40px;
}


@media only screen and (min-width: 1130px) and (max-width: 1430px) {
  .aijokercat-links-grid {
      gap: 200px;
  }
} 
@media only screen and (min-width: 768px) and (max-width: 1130px) {
  .aijokercat-links-grid {
      gap: 100px;
  }
  .aijokercat-quick-links-section {
      margin-left: 200px;
  }
} 



@media only screen and (min-width: 250px) and (max-width: 768px) {
  .aijokercat-quick-links-section {
      width: 60%;
      text-align: center;
      margin-bottom: 20px;
      margin-left:0px;
      }
      .aijokercat-logo-container {
          justify-content: center;
          margin-bottom: 20px;
      }
      .aijokercat-social-icons {
          display: none;
      }
      .aijokercat-copyright {
          display: none;
      }
      .aijokercat-links-grid {
          justify-content: center;
          gap: 160px;
      }
      .aijokercat-contact-section {          
              display: none;
      }
      .aijokercat-link::after {
         display: none;
      }
      .aijokercat-quick-links-title {
          display: none;
      }
      .aijokercat-footer-bottom {
          width: 100%;
          display: block;
      }
      .aijokercat-link {
          font-family: 'Satoshi', sans-serif;
          color: white;
          position: relative;
          text-decoration: none;
          transition: color 0.3s;
          width: 109px;
      }
      .aijokercat-logo {
          width: 120px;
          height: 120px;
          border-radius: 50%;
      }
} 


@media only screen and (min-width: 332px) and (max-width: 390px) {
  .aijokercat-links-grid {
      gap: 100px;
  }
} 

@media only screen and (min-width: 150px) and (max-width: 331px) {
  .aijokercat-quick-links-section {
      width: 60%;
      text-align: center;
      margin-bottom: 20px;
      margin-left:0px;
      }
      .aijokercat-logo-container {
          justify-content: center;
          margin-bottom: 20px;
      }
      .aijokercat-social-icons {
          display: none;
      }
      .aijokercat-copyright {
          display: none;
      }
      .aijokercat-links-grid {
          justify-content: center;
          gap: 50px;
      }
      .aijokercat-contact-section {          
              display: none;
      }
      .aijokercat-link::after {
         display: none;
      }
      .aijokercat-quick-links-title {
          display: none;
      }
      .aijokercat-footer-bottom {
          width: 100%;
          display: block;
      }
      .aijokercat-link {
          font-family: 'Satoshi', sans-serif;
          color: white;
          position: relative;
          text-decoration: none;
          transition: color 0.3s;
          width: 109px;
      }
      .aijokercat-logo {
          width: 120px;
          height: 120px;
          border-radius: 50%;
      }
}